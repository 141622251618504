/* Welcome.css */

.welcome-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #ff7eb3, #ff758c);
  color: white;
  font-family: "Arial", sans-serif;
}

.welcome-content {
  text-align: center;
  max-width: 600px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  font-weight: bold;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

p {
  font-size: 1.2rem;
  margin-bottom: 30px;
  line-height: 1.6;
}

.get-started-button {
  font-size: 1rem;
  padding: 10px 20px;
  background: white;
  color: #ff758c;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
}

.get-started-button:hover {
  background: #ff758c;
  color: white;
}
